<template>
	<div class="border rounded-sm py-5 text-center">
		<div><i class="ri-error-warning-line" style="font-size: 80px; line-height: 1.2" /></div>
		<h2>{{ title }}</h2>
		<p style="font-size: 18px;">{{ message }}</p>
	</div>
</template>

<script>
	export default {
		props: {
			title: {
				type: String,
				default: null,
			},
			message: {
				type: String,
				default: null,
			}
		}
	};
</script>
