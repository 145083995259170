<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('warning')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="$t('warning')" :is-filter="false"/>
            </template>
            <warning-page :title="$t('information')" :message="$t('speacial_student_error')"/>
        </app-layout>
    </div>
</template>

<script>

import WarningPage from '@/components/page/WarningPage';
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue";

export default {
    components: {
        HeaderMobile,
        Header,
        AppLayout,
        WarningPage
    },
}
</script>
